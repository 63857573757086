/*============================================================================*\
  Wysiwyg Component
\*============================================================================*/

.wysiwyg {
  color: theme('colors.lilac');

  h1 {
    /* stylelint-disable-next-line */
    @extend .page-title;
    color: theme('colors.lilac');
  }

  h3 {
    @apply text-paragraph mt-4 mb-1 leading-h3;
    color: theme('colors.coral');

    @screen s {
      @apply text-h3;
    }
  }

  h2 {
    @apply text-h3 leading-h2;
    color: theme('colors.coral');

    @screen m {
      @apply text-h2;
    }
  }

  p {
    @apply text-base;

    @screen m {
      @apply text-paragraph;
    }
  }

  a {
    color: theme('colors.lilac');
    text-decoration: underline;

    &:hover {
      color: theme('colors.pink.bubblegum');
    }
  }

  ul {
    margin-bottom: 2rem;
    list-style-type: none;

    li:before {
      content: '—';
      float: left;
      margin-right: 8px;
    }
  }
}
