/*============================================================================*\
  Side menu components

  @see src/js/components/_side-menu.js
  @see templates/components/side-menu.twig
\*============================================================================*/

/*============================================================================*\
  Side Menu block
\*============================================================================*/

.side-menu {
  z-index: theme('zIndex.menu');
  position: relative;
}

/*============================================================================*\
  Btn element
\*============================================================================*/

.side-menu__btn {
  z-index: 1;
  position: relative;
}

/*
  Btn close modifier
\*============================================================================*/

.side-menu__btn--close {
  @apply text-lilac;
  top: 38px;
  display: none;

  @screen m {
    @apply text-grey;
  }

  .is-open & {
    display: flex;
  }
}

/*
  Btn open modifier
\*============================================================================*/

.side-menu__btn--open {
  display: block;

  .is-open & {
    display: none;
  }
}

/*============================================================================*\
  Btn icon element
\*============================================================================*/

body {
  color: theme('colors.lilac');
}

/*============================================================================*\
  Btn label element
\*============================================================================*/

.side-menu__btn-label {
  @apply text-lilac;
  margin-top: 0.3rem;
  margin-left: 2rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;

  @screen m {
    display: none;
  }
}

/*============================================================================*\
  Btn panel element
\*============================================================================*/

.side-menu__panel {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  overflow-y: scroll;
  background-color: theme('colors.cream');
  transform: translateX(-101%);
  transition: transform 0.6s $out-expo;

  @screen m {
    @apply text-lilac;
    align-items: start;
    justify-content: start;
    max-width: 40rem;
    padding: 6em 3em 6em 7em;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: none;
    border-right: 3px solid theme('colors.lilac');
  }

  .is-open & {
    pointer-events: all;
    transform: translateX(0);
  }
}

/*============================================================================*\
  Menu element
\*============================================================================*/

.side-menu__menu {
  margin-top: theme('spacing.20');

  .menu-item {
    margin: theme('spacing.6') 0;
  }

  a {
    @apply text-2xl;
    color: theme('colors.lilac');
    text-align: center;

    @screen m {
      color: theme('colors.lilac');
      text-align: left;
      opacity: 0.5;
    }

    @screen xxl {
      @apply text-h2;
    }
  }

  a:hover {
    opacity: 0.7;

    @screen m {
      opacity: 1;
    }
  }
}

/*============================================================================*\
  Menu small modifier
\*============================================================================*/

.side-menu__menu--small {
  .menu-item {
    margin: theme('spacing.1') 0;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    @apply text-itemMenu;
    margin: 0 theme('spacing.2');

    @screen m {
      margin: 0;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @screen m {
      display: block;
    }
  }
}
