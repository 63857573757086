/*============================================================================*\
  Fest
\*============================================================================*/

/*============================================================================*\
  Titre
\*============================================================================*/

.fest {
  margin-bottom: theme('spacing.20');

  &:last-child {
    margin-bottom: 0;
  }
}

/*============================================================================*\
  fest-layout
\*============================================================================*/

.fest__row-reverse {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @screen s {
    flex-direction: row-reverse;

    img {
      float: right;
    }
  }
}

/*============================================================================*\
  historique-contenu
\*============================================================================*/

.historic__image {
  display: block;
  width: 100%;
  max-width: 100%;
}
