/*============================================================================*\
  Popin Component
\*============================================================================*/
.popin {
  z-index: theme('zIndex.popin');
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s $out-expo;

  &.is-open {
    pointer-events: all;
    opacity: 1;
  }
}

.popin__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.popin__container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80vw;
  padding: theme('spacing.5') theme('spacing.3');
  background-color: theme('colors.tangerine');
  border-radius: 20px;
  transform: translate3d(-50%, -50%, 0);

  @screen m {
    width: 60vw;
    padding: theme('spacing.6');
    border-radius: 40px;
  }
}

.popin__content {
  max-height: 60vh;
  overflow: auto;

  @screen m {
    max-height: 90vh;
  }
}

.popin__close {
  position: absolute;
  top: theme('spacing.3');
  right: theme('spacing.3');
  line-height: 1;
  cursor: pointer;

  svg {
    width: theme('spacing.2');
    height: theme('spacing.2');
  }

  @screen m {
    top: theme('spacing.4');
    right: theme('spacing.4');
  }
}
