/*============================================================================*\
   Loading Screen
\*============================================================================*/

.loader {
  z-index: 800;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  pointer-events: none;
}

.has-loader .loader {
  display: inline-block;
}
