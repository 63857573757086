/*============================================================================*\
  Link
\*============================================================================*/

.link {
  color: theme('colors.lilac');
  white-space: nowrap;
  transition: color 0.1s;

  svg {
    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }

  &:hover {
    color: theme('colors.coral');
  }
}

.link--white {
  color: theme('colors.white');

  &:hover {
    color: theme('colors.redIndian');
  }
}

.link--studio-meta {
  display: inline-block;
  overflow: hidden;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    .icon--hover {
      width: 100%;
      opacity: 1;
    }
  }

  span {
    display: inline-block;
  }
}

.link__contact {
  opacity: 1;

  a {
    opacity: 0.8;

    &:hover {
      opacity: 1;
      transition: opacity 0.1s linear;
    }
  }
}
