.ticket {
  position: relative;
  padding-right: 70px;
  overflow: hidden;
  background-color: theme('colors.sunny');
  border-radius: 20px;

  @screen l {
    padding-right: 125px;
  }
}

.ticket__status {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 100%;
  color: white;
  text-align: center;
  letter-spacing: 2px;
  background-color: theme('colors.lilac');

  @screen l {
    width: 125px;
  }

  p {
    white-space: nowrap;
    transform: rotate(270deg);
  }
}

.ticket--disabled {
  cursor: not-allowed;
  @apply text-lilac/30;

  button {
    pointer-events: none;
    opacity: 0.3;
  }

  .btn:before {
    display: none;
  }

  .ticket__status {
    background-color: #efbbb9b2;
  }

  &.ticket {
    background-color: #f8d68d;
  }
}

.ticket .btn {
  margin-right: 0;
  background: theme('colors.sand.DEFAULT');

  @screen xl {
    margin-right: 64px;
  }
}
