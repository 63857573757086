/*============================================================================*\
  Pagination Component
\*============================================================================*/

.pagination__text {
  width: 32px;
  height: 32px;
  text-indent: -1px;
  border-radius: 100px;
  transition: background-color 0.2s;
}
