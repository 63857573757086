/*============================================================================*\
  Contact
\*============================================================================*/

.contact__title {
  h1 {
    font-size: theme('fontSize.h1');
  }

  h3 {
    font-size: theme('fontSize.h3');
  }
}
