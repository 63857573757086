.artist__name,
.artist__style {
  font-weight: 600;
  text-transform: uppercase;
}

.artist__title {
  color: theme('colors.tangerine');
}

.artist__image {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.1s linear;
  }
}

.artist__image-availability {
  z-index: 10;
  position: absolute;
  bottom: 10px;
  left: 15px;
  transition: color 0.1s linear;
}

.artist__link:hover {
  .artist__image {
    &::after {
      opacity: 0.85;
    }
  }

  .artist__image-availability {
    color: theme('colors.tangerine');
  }

  .illus {
    opacity: 1;
  }
}
