/*============================================================================*\
  Button
\*============================================================================*/

.btn {
  position: relative;
  display: inline-block;
  padding: 0.5rem 2rem;

  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;

  border: 0;
  border-radius: 50px;
  outline: none;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: inherit;
    transform: translate3d(0, 0.35em, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  }

  &:hover {
    transform: translate(0, 0.15em);

    &::before {
      transform: translate3d(0, 0.2em, -1em);
    }
  }

  &:active {
    transform: translate(0em, 0.25em);

    &::before {
      transform: translate3d(0, 0.1em, -1em);
    }
  }
}

.btn--primary {
  font-size: 1.25rem;
  color: theme('colors.lilac');
  background: theme('colors.pink.bubblegum');
  border: 3px solid theme('colors.lilac');

  &::before {
    background-color: theme('colors.lilac');
  }
}

.btn--secondary {
  font-size: 1.25rem;
  @screen s {
    font-size: 2rem;
  }
  color: theme('colors.lilac');
  background: theme('colors.blue.baby');
  border: 3px solid theme('colors.lilac');

  &::before {
    background-color: theme('colors.lilac');
  }
}

.btn--tertiary {
  color: theme('colors.lilac');
  background: theme('colors.sunny');
  border: 3px solid theme('colors.lilac');

  &::before {
    background-color: theme('colors.lilac');
  }
}

.btn--tertiary-cream {
  color: theme('colors.lilac');
  background: theme('colors.cream');
  border: 3px solid theme('colors.lilac');

  &::before {
    background-color: theme('colors.lilac');
  }
}
