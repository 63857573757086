/*============================================================================*\
   Base
\*============================================================================*/

html {
  @apply font-ultra text-white;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: theme('colors.cream');

  ::selection {
    background: theme('colors.purple');
  }
}

.main {
  flex: 1 0 auto;
  width: 100%;
  max-width: 1284px;
  margin-right: auto;
  margin-left: auto;
  padding-right: theme('spacing.3');
  padding-left: theme('spacing.3');

  @screen s {
    padding-right: theme('spacing.4');
    padding-left: theme('spacing.4');
  }
}

button {
  cursor: pointer;
  outline: 0 !important;

  &:not(.btn) {
    border: 0 !important;
  }
}

.border--double {
  @apply rounded-[30px] border-[7px] border-white relative after:border-[3px] after:border-lilac after:top-0 after:left-0 after:rounded-[22px] after:h-full after:w-full after:absolute after:pointer-events-none overflow-hidden;
}

.page-title {
  @apply text-h2 leading-h1;
  margin-bottom: theme('spacing.3');

  @screen m {
    @apply text-h1;
    margin-bottom: theme('spacing.5');
  }

  &::before {
    content: '';
    position: relative;
    top: 3px;
    display: inline-block;
    width: 5px;
    height: 30px;
    margin-right: 15px;
    background-color: theme('colors.coral');

    @screen m {
      top: 4px;
      height: 45px;
      margin-right: 27px;
    }
  }
}

.white__thumb {
  width: 100%;
  height: 15rem;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

.section {
  margin-bottom: theme('spacing.8');

  @screen m {
    margin-bottom: theme('spacing.10');
  }
}
