/*============================================================================*\
  File list component
\*============================================================================*/

.file-list__squared {
  min-height: 11rem;
}

.file-list--press .file-list__squared {
  background-color: theme('colors.tangerine');
  transition: background-color 0.4s $out-expo;

  &:hover {
    background-color: theme('colors.sand.DEFAULT');
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.file-list--artists .file-list__squared {
  img {
    transition: opacity 0.4s $out-expo;
  }

  &:hover {
    img {
      opacity: 0.6;
    }

    .file-list__artist-download {
      color: theme('colors.pink.baby');
    }
  }
}
