.crossnav__item-previous {
  text-align: right;

  .icon {
    position: absolute;
    right: 30px;
  }
}

.crossnav__item-next {
  text-align: left;

  .icon {
    position: absolute;
    left: 30px;
  }
}

.crossnav__item-next,
.crossnav__item-previous {
  @apply rounded-sm;
  position: relative;
  display: flex !important;
  background-color: theme('colors.pink.bubblegum');
  border: 3px solid theme('colors.lilac');
  transition: background-color 0.2s linear;

  &:hover {
    background-color: theme('backgroundColor.sand.DEFAULT');
  }
}
