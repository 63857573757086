/*============================================================================*\
  Icons
\*============================================================================*/

/*   Icon Down triangle
\*============================================================================*/

.icon--down-triangle {
  svg {
    width: 16px;
    height: auto;
    fill: theme('colors.grey');
  }
}

/*   Icon Studio Meta
\*============================================================================*/

.icon--sm {
  &,
  svg {
    display: block;
    width: 4.93em;
    height: 0.79em;
  }

  svg {
    fill: theme('colors.lilac');
  }

  &.icon--hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;

    svg {
      fill: #fff;
    }
  }
}
