/*============================================================================*\
  Homepage
\*============================================================================*/

.layer {
  max-width: 1920px;
  margin: auto;
}

.layer--vitrine {
  max-width: 1020px;
  margin: auto;
}

.layer__flat {
  scale: 1.08;
}

.layer__bottom--over {
  scale: 1.15;
  transform-origin: top;
}

.layer--container {
  top: 90px;
}

.layer__bottom--under {
  scale: 1.5;
  transform-origin: top;
  @screen m {
    scale: 1;
  }
}

.layer--bottom-footer {
  top: -100%;
}
/*============================================================================*\
  Video
\*============================================================================*/
.video {
  border-radius: 20px;

  @screen s {
    border-radius: 40px;
  }
}

/*============================================================================*\
  Artist
\*============================================================================*/

.home__artist-title {
  @apply text-4xl s:text-6xl;
  padding-bottom: 4rem;
  color: theme('colors.lilac');
  text-align: center;
}

.home__artist-list {
  margin: 6px;
  padding: 5px 20px;
  font-size: theme('fontSize.xl');
  font-weight: 700;
  background-color: theme('colors.cream');
  border-radius: 50px;

  @screen s {
    margin: 12px;
    padding: 10px 40px;
    @apply text-3xl;
  }
}

.date-marquee {
  animation: 30s infinite linear slidein;

  @screen s {
    animation: 50s infinite linear slidein;
  }
}

@keyframes slidein {
  from {
    transform: translate3D(0, 0, 0);
  }

  to {
    transform: translate3D(-100%, 0, 0);
  }
}

/*============================================================================*\
  Information
\*============================================================================*/
.information__title {
  font-size: theme('fontSize.5xl');
  color: theme('colors.grey');
  text-align: center;
}

.information__link--container-pratique {
  position: relative;
  height: 100%;
  margin: 0 auto;
  background-image: url('#{$img-dir}infos-pratique.png');
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    opacity: 0;
    transition: 0.5s opacity $out-expo;
  }
}

.information__link--container-billetterie {
  /* stylelint-disable-next-line */
  @extend .information__link--container-pratique;
  background-image: url('#{$img-dir}infos-billeterie.png');
}
