@import '~flickity/css/flickity';

.slider-text {
  z-index: theme('zIndex.over');
  position: relative;
  left: 0;
  width: 100%;
  height: 400px;
  margin-top: 20px;
  padding: theme('spacing.7');
  color: white;
  background-color: theme('colors.coral');
  border: 7px solid white;
  border-radius: 30px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border: 3px solid theme('colors.lilac');
    border-radius: 22px;
  }

  @media screen and (min-width: 340px) {
    height: 356px;
  }
  @screen s {
    position: absolute;
    top: 70px;
    width: 396px;
    padding: theme('spacing.10');
  }

  @screen m {
    left: -100px;
  }

  /* stylelint-disable-next-line order/order */
  .flickity-button-icon {
    top: 30% !important;
    left: 27% !important;
    width: 40% !important;
    height: 40% !important;
    fill: theme('colors.lilac');
  }

  /* stylelint-disable-next-line order/order */
  .flickity-button {
    top: calc(100% - 70px);
    background-color: theme('colors.white');
    border: 3px solid theme('colors.lilac') !important;
    transition: background-color 0.4s $out-expo, border-color 0.4s $out-expo;

    &:hover {
      color: theme('colors.white');
      background-color: transparent;

      .flickity-button-icon {
        fill: white;
      }
    }

    &.previous {
      left: 25px;

      @screen xs {
        left: 40px;
      }
    }

    &.next {
      left: 85px;

      @screen xs {
        left: 100px;
      }
    }
  }

  .flickity-page-dots {
    position: absolute;
    right: 25px;
    bottom: 70px;
    width: 80px;

    @screen xs {
      right: 40px;
    }

    .dot {
      opacity: 1;

      &:not(.is-selected) {
        background-color: theme('colors.grey');
      }

      &.is-selected {
        background-color: theme('colors.white') !important;
      }
    }
  }
}

.slider-text__cell {
  width: 100%;
  height: 100%;

  h3 {
    font-size: 1.8rem;

    @screen s {
      font-size: 2rem;
    }
    text-transform: uppercase;
  }

  p {
    margin-top: theme('spacing.1');
  }
}

.slider-media {
  width: 100% !important;
  height: 226px !important;
  background-color: white;
  border: 3px solid theme('colors.lilac');
  border-radius: 40px;

  @screen s {
    left: 110px;
    width: 87% !important;
    height: 396px !important;
  }

  @screen m {
    width: 100% !important;
    height: 496px !important;
  }

  .flickity-viewport {
    @screen s {
      border-radius: 30px;
    }
  }
}

.slider-media__cell {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
