/*============================================================================*\
  Accordion Component
\*============================================================================*/

.accordion__content {
  height: 0;
  overflow: hidden;
}

.icon--down-triangle {
  transform: rotate(0);
  transition: transform 1s $out-expo;
}

.accordion.is-open {
  .accordion__content {
    height: auto;
    overflow: visible;
    /* stylelint-disable-next-line */
    transition: height 0.3s ease;
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }

  .icon--down-triangle {
    transform: rotate(-180deg);
  }
}

.accordion__title {
  border-left: 4px solid theme('colors.coral');
}

/* Fade animation of the accordion
\*============================================================================*/

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
