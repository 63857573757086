/* stylelint-disable no-invalid-position-at-import-rule */
/*============================================================================*\
  App styles
\*============================================================================*/
@import 'tools/easings';
$img-dir: '/wp-content/themes/decibulles/static/img/layout/';
$svg-dir: '/wp-content/themes/decibulles/static/svg/';

@tailwind base;
@import 'globals/base';
@import 'globals/header';
@import 'globals/side-menu';
@import 'globals/footer';
@import 'globals/homepage';
@import 'globals/crossnav';
@import 'pages/loader';

/*============================================================================*\
  Components styles
\*============================================================================*/

@tailwind components;
@import 'components/artist';
@import 'components/btn';
@import 'components/link';
@import 'components/file-list';
@import 'components/wysiwyg';
@import 'components/accordion';
@import 'components/icons';
@import 'components/contact';
@import 'components/ticket';
@import 'components/pagination';
@import 'components/festival';
@import 'components/popin';
@import 'components/slider';

@import 'pages/single-post';

@tailwind utilities;
