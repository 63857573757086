/*============================================================================*\
  Header
\*============================================================================*/

.header .icon--logo-decibulles svg {
  width: 200px;
  fill: theme('colors.white');

  @screen s {
    width: 250px;
  }
}
