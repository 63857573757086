.post__thumbnail .figure img {
  min-width: 100%;
}

.social-network__link {
  margin-right: theme('spacing.5');

  &:last-child {
    margin-right: 0;
  }

  .icon svg {
    width: 25px;
    height: 25px;
    opacity: 1;
    transition: opacity 0.1s linear;

    @screen s {
      width: 35px;
      height: 35px;
    }
  }

  &:hover .icon svg {
    opacity: 0.6;
  }
}

.video-container iframe {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
